// deps
import { forwardRef } from "react";
import { Box, useColorModeValue } from "@chakra-ui/react";

const StyledField = forwardRef(
  /**
   * @typedef {object} Props
   * @property {boolean} isOpen
   * @property {any} children
   */

  /**
   * @param {import("react").PropsWithChildren<Props>} props
   * @returns {import("react").FunctionComponentElement<Props>}
   */
  function StyledField(props, ref) {
    const { isOpen, children, ...otherProps } = props;
    const bgColor = useColorModeValue("white", "dark");
    const borderColor = useColorModeValue("gray.300", "gray.600");

    return (
      <Box
        position="relative"
        background={bgColor}
        border="1px solid"
        borderColor={borderColor}
        rounded="md"
        transition="all 200ms"
        display="flex"
        alignItems="center"
        px="1"
        py=".4rem"
        {...(isOpen && {
          borderColor: "blue.500",
          boxShadow: "0 0 0 1px var(--chakra-colors-blue-500)",
        })}
        _hover={{
          borderColor: "gray.300",
        }}
        _focusWithin={{
          borderColor: "blue.500",
          boxShadow: "0 0 0 1px var(--chakra-colors-blue-500)",
        }}
        {...otherProps}
        ref={ref}>
        {children}
      </Box>
    );
  },
);

export default StyledField;
